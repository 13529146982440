body {
    height: 100%;
    background-color: #f2f2f2;
}
html {
    height: 100%;
    margin: 0;
    padding: 0;
}
#app {
    height: 100%;
    display: flex;
    flex-direction: column;
}
#root {
    height: 100%;
}
#footer {
    padding: 0 1%;
}
.highlight-term {
    background-color: #ff6200;
    color: #fff;
}
